import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Carousel from "nuka-carousel"

export default class IndexPage extends React.Component {
  render() {
    const { marques } = this.props
    return (
      <div class="section py-0">
        <div class="container z-2">
          <div class="row position-relative justify-content-center align-items-cente">
            <div class="col-12">
              <div class="card border-light">
                <div class="card-body text-left px-5 py-4">
                  <div class="row align-items-center">
                    <div class="col-12 mt-5 mt-md-0 col-md-5">
                      <p class="lead h3">Top marques des voitures au maroc </p>
                      <div class="row mb-2">
                        <div class="col p-1 mb-0">
                          <ul class="list-group mb-3">
                            {marques.slice(0, 6).map(({ node: marque }) => (
                              <li
                                key={marque.id}
                                className="list-group-item text-gray p-0 mb-2"
                              >
                                <Link className="" to={`${marque.path.alias}/`}>
                                  <div class="d-flex p-0">
                                    <div>
                                      <div class="icon icon-secondary">
                                        <Img
                                          className="card-img-top rounded-circle border-0"
                                          style={{ width: "45px" }}
                                          fluid={
                                            marque.relationships.field_image
                                              .localFile.childImageSharp.fluid
                                          }
                                          alt={marque.name}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div class="col p-1 mb-0">
                          <ul class="list-group mb-3">
                            {marques.slice(6, 12).map(({ node: marque }) => (
                              <li
                                key={marque.id}
                                className="list-group-item text-gray p-0 mb-2"
                              >
                                <Link className="" to={`${marque.path.alias}/`}>
                                  <div class="d-flex p-0">
                                    <div>
                                      <div class="icon icon-secondary">
                                        <Img
                                          className="card-img-top rounded-circle border-0"
                                          style={{ width: "45px" }}
                                          fluid={
                                            marque.relationships.field_image
                                              .localFile.childImageSharp.fluid
                                          }
                                          alt={marque.name}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div class="col p-1 mb-0">
                          <ul class="list-group mb-3">
                            {marques.slice(12, 18).map(({ node: marque }) => (
                              <li
                                key={marque.id}
                                className="list-group-item text-gray p-0 mb-2"
                              >
                                <Link className="" to={`${marque.path.alias}/`}>
                                  <div class="d-flex p-0">
                                    <div>
                                      <div class="icon icon-secondary">
                                        <Img
                                          className="card-img-top rounded-circle border-0"
                                          style={{ width: "45px" }}
                                          fluid={
                                            marque.relationships.field_image
                                              .localFile.childImageSharp.fluid
                                          }
                                          alt={marque.name}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div class="col p-1 mb-0">
                          <ul class="list-group mb-3">
                            {marques.slice(18, 24).map(({ node: marque }) => (
                              <li
                                key={marque.id}
                                className="list-group-item text-gray p-0 mb-2"
                              >
                                <Link className="" to={`${marque.path.alias}/`}>
                                  <div class="d-flex p-0">
                                    <div>
                                      <div class="icon icon-secondary">
                                        <Img
                                          className="card-img-top rounded-circle border-0"
                                          style={{ width: "45px" }}
                                          fluid={
                                            marque.relationships.field_image
                                              .localFile.childImageSharp.fluid
                                          }
                                          alt={marque.name}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-7 mt-5 mt-md-0">
                      <p class="lead h3">
                        Promotion des voitures neuves au maroc{" "}
                      </p>

                      <Carousel withoutControls={true} autoplay={true}>
                        <img
                          alt="Opel astra promo maroc"
                          src="https://mauto.ma/static/20922a147963df240ce9e7fefa4fa868/58556/920x600-OPEL-Astra-Juin-2021.webp"
                        />
                        <img
                          alt="Peugeot 208 promo maroc"
                          src="https://mauto.ma/static/f6d919682d5bdbdd1f9a9ab5ddd733a7/58556/920x600-PEUGEOT-208-BVA-Juillet-2021-VF.webp"
                        />
                        <img
                          alt="Citroen C3 promo maroc"
                          src="https://mauto.ma/static/99619a17c998ced76d2ccee2463314c0/58556/920x600-CITROEN-Fil-Rouge-C3-Avril-2021.webp"
                        />
                        <img
                          alt="Seat arona promo maroc"
                          src="https://mauto.ma/static/2851af0716e35b574925d9ffeed1805a/58556/920x600-SEAT-Arona-Juillet-2021.webp"
                        />
                        <img
                          alt="Nissan promo maroc"
                          src="https://mauto.ma/static/41c05b9f4d958494a108a3b75fe781c4/58556/920x600-NISSAN-Juke-juillet-2021-Bis.webp"
                        />
                        <img
                          alt="Tucons promo maroc"
                          src="https://mauto.ma/static/be768c1f87401fa28f5d5666b698eca0/58556/920x600-HYUNDAI-Tucson-2021-Neuve-Maroc.webp"
                        />
                        <img
                          alt="Ford fusion promo maroc"
                          src="https://mauto.ma/static/ec6b62d1703b2c8ccb66c389e02d11e4/58556/920x600-FORD-Fusion-2021-Neuve-Maroc.webp"
                        />
                        <img
                          alt="Ford fiesta promo maroc"
                          src="https://mauto.ma/static/ec6b62d1703b2c8ccb66c389e02d11e4/58556/920x600-FORD-Fusion-2021-Neuve-Maroc.webp"
                        />
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

IndexPage.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}
